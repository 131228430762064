<template>
  <div id="Login" class="login fullheight" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer fluid class="fullheight"> 
      <CRow class="align-start align-md-center fullheight">
        <CCol cols="12" md="12" :style="!is_finished ? 'margin-top: 30px !important' : 'margin-top: 100px !important'">
          <h1>Política de Privacidad para Gobbots</h1>
          <b><i>Última actualización: 11/11/2024</i></b><br/>
          <br/>
          <h3>1. Introducción</h3>
          <br/>
          En Gobbots, nos comprometemos a proteger la privacidad de nuestros usuarios. Esta Política de Privacidad explica cómo recopilamos, usamos, compartimos y protegemos la información personal de los usuarios que interactúan con nuestro chatbot, Gobbots, a través de plataformas como WhatsApp.<br/>
<br/>
          <h3>2. Información que Recopilamos</h3>
          El chatbot puede recopilar información personal para brindar una experiencia personalizada. Esto incluye:<br/>
<br/>
          Datos proporcionados por el usuario: Como nombre, dirección, número de teléfono, correo electrónico o cualquier otra información que el usuario proporcione al interactuar con el bot.<br/>
          Datos de interacción: Información sobre el uso del bot, como consultas realizadas, respuestas dadas y tiempos de uso.<br/>
          Información técnica: Dirección IP, tipo de dispositivo y sistema operativo, siempre que sea necesario para optimizar el rendimiento del servicio.<br/>
          <br/>
          <h3>3. Uso de la Información</h3>
          Utilizamos la información recopilada para:<br/>
<br/>
          Proporcionar y personalizar los servicios del chatbot.<br/>
          Responder a consultas y facilitar trámites municipales (si corresponde).<br/>
          Mejorar el rendimiento del chatbot y desarrollar nuevas funcionalidades.<br/>
          Enviar notificaciones y actualizaciones relacionadas con el servicio.<br/><br/>
          <h3>4. Compartición de Información</h3>
          No compartimos, vendemos ni alquilamos información personal a terceros. La información del usuario solo puede ser compartida en los siguientes casos:<br/>
<br/>
          Con el personal autorizado de [Nombre del Municipio o Empresa] para responder a solicitudes o resolver incidencias.<br/>
          Cuando sea necesario para cumplir con leyes aplicables o regulaciones gubernamentales.<br/><br/>
          <h3>5. Seguridad de la Información</h3>
          Implementamos medidas de seguridad administrativas, técnicas y físicas para proteger la información personal contra accesos no autorizados, pérdida o alteración. Sin embargo, es importante recordar que ninguna transmisión de datos en Internet es 100% segura.<br/>
<br/>
          <h3>6. Retención de Datos</h3>
          La información recopilada se retendrá solo el tiempo necesario para cumplir con los propósitos descritos en esta política, salvo que se requiera un período de retención más largo por ley.<br/>
<br/>
          <h3>7. Derechos del Usuario</h3>
          Los usuarios tienen derecho a:<br/>
<br/>
          Acceder y revisar la información personal que hemos recopilado.<br/>
          Solicitar la corrección de información incorrecta o incompleta.<br/>
          Solicitar la eliminación de su información, sujeto a los requisitos legales aplicables.<br/><br/>
          <h3>8. Cambios en la Política de Privacidad</h3>
          Esta Política de Privacidad puede actualizarse ocasionalmente para reflejar cambios en nuestras prácticas o por motivos legales. Notificaremos a los usuarios sobre cambios importantes a través de la plataforma o mediante un aviso visible en el sitio web.<br/>
<br/>
          <h3>9. Contacto</h3>
          Si tienes preguntas o inquietudes sobre nuestra Política de Privacidad o el manejo de tu información personal, puedes contactarnos en:<br/>
<br/>
          Correo electrónico: contacto@gobbots.com<br/>
          Teléfono: 3751050079<br/>
          <br/>
          <br/>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>

import router from '../../../router/index'
import login from '../../../services/login';
import general_ws from '../../../services/general';
import store from '../../../store'

import axios from 'axios'

import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"

export default {
    name: 'Privacy',
    components: {

    },
    data () {
        return {
            is_finished: false,
            form: {
                levels: 1,
                bedrooms: 2,
                bathrooms: 1,
                stove: false,
                fridge: false,
                sanitization: false,
                regimen: '626',
                commercial_name: '',
                phone: '',
                email: '',
                password: '',
                state: 'JAL',
                company_type: 'services',
                company_size: 'small',
                admin_tool: 'none',
                huso_horario: 'CENTRO',
                system: '',

            },
            rfc_error: 0,
            email_error: 0,
            show_password: false,
            submitted: false,
            states: [],
            statesOptions: [],
            regimens: [],
            regimensOptions: [],
        }
    },
    mounted: async function() {
      let response = await general_ws.getRegimens(); 

      if(response.type == "success"){
        this.regimens = response.data;
      }

      let response2 = await general_ws.getStates(); 

      if(response2.type == "success"){
        this.states = response2.data;
      }

      this.regimensOptions = await this.$parseSelectOptionsOrdered(this.regimens, "code", "code:name");
      this.statesOptions = await this.$parseSelectOptionsOrdered(this.states, "code", "name");
    },
    methods: {
        async singup () {
            this.$v.$touch();

            if(!this.validatePassword(this.form.password)){
              return;
            }
            let resRFC = await this.validateRFC(this.form.rfc);
            if(!resRFC){
              return false;
            }
            let resEmail = await this.validateEmail(this.form.email);
            if(!resEmail){
              return false;
            }

            if (this.isValid) {
                this.submitted = true;
                
                let response = await login.storeAccount(this.form);
                
                if(response.type == "success"){
                  //SI SE DEFINIÓ LA CONTRASEÑA SE HACE EL PRIMER LOGIN
                  if(this.form.password.length >= 8){
                    let params = {
                      email: this.form.email,
                      password: this.form.password,
                      device_name: navigator.userAgent
                    };

                    let response = await login.login(params);

                    if(response.type == "success"){
                      let data = response.data;

                      localStorage.setItem("token", data.api_token);

                      axios.defaults.headers.common['api-token'] = data.api_token;

                      router.push("home");
                    }
                  }
                  else{
                    //SI NO SE DEFINIÓ LA CONTRASEÑA - SE ENVIA UN CORREO DE ACTIVACIÓN
                  }
                  
                  this.is_finished = true;
                }
                else{
                  alert(response.message);
                }
            }
            
        },
        back(){
          router.push("login");
        },
        async validateRFC (rfc) {
            let response = await login.validateRFC(rfc);
            
            if(response.type == "success" && response.data == 1){
              alert(response.message);
              this.form.rfc = "";

              return false;
            }

            return true;
        },
        async validateEmail (email) {
            let response = await login.validateEmail(email);

            if(response.type == "success" && response.data == 1){              
              alert(response.message);
              this.form.email = "";

              return false;
            }

            return true;
        },
        validatePassword (password) {
            if(password.length > 0 && password.length < 8){
              alert("El campo contraseña debe tener al menos 8 caracteres");
              return false;
            }

            return true;
        },
        checkIfValid (fieldName) {
            const field = this.$v.form[fieldName]
            if (!field.$dirty) {
                return null
            }

            if(fieldName == "email"){
              if(field.$model == "raalveco@gmail.com"){
                this.show_password = true;
              }
            }
            
            return !(field.$invalid)
        },
        showToast(type, message){
            store.commit('toast', {color: type, message: message});
        },
        loading(){
            store.commit('loading');
        },
        loaded(){
            store.commit('loaded');
        }
    },
    computed: {
        formString () { return JSON.stringify(this.form, null, 4) },
        isValid () { return !this.$v.form.$invalid },
        isDirty () { return this.$v.form.$anyDirty },
    },
    mixins: [validationMixin],
    validations: {
        form: {
            levels: {
                required
            },
            bedrooms: {
                required
            },
            bathrooms: {
                required
            },
            stove: {
                required
            },
            fridge: {
                required
            },
            sanitization: {
                required
            },
            rfc: {
                required
            },
            name: {
                required
            },
            regimen: {
                required
            },
            commercial_name: {
                required
            },
            phone: {
                required
            },
            email: {
                required,
                email
            },
            password: {},
            state: {
                required
            },
            huso_horario: {},
            company_type: {
                required
            },
            company_size: {
                required
            },
            admin_tool: {
                required
            },
            system: {}
        }
    },
}
</script>